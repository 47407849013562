/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
body {
  margin: 0px;
}
.SignInModal {
  /* BASIC */

  html {
    background-color: #56baed;
  }

  body {
    font-family: "Poppins", sans-serif;
  }

  a {
    color: #0d296a;
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
  }

  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin: 40px 8px 10px 8px;
    color: #cccccc;
  }

  /* STRUCTURE */

  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    height: 100vh;
  }
  .hidePassword {
    position: relative;
    margin-left: -37px;
    margin-top: 15px;
  }
  #formContent {
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #dce8f4;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    -webkit-box-shadow: -2px 5px 10px 0 rgba(0, 0, 0, 0.3);
    box-shadow: -2px 5px 10px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
  }

  #formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
  }

  /* TABS */

  h2 {
    color: #0d296a;
    font-weight: bold;
    font-size: 3rem;
  }

  /* FORM TYPOGRAPHY*/

  input[type="button"],
  button,
  input[type="submit"],
  input[type="reset"] {
    background-color: #56baed;
    border: none;
    color: #456bbc;
    padding: 5px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  input[type="button"],
  button,
  input[type="submit"],
  input[type="reset"] {
    border-radius: 25px;
    background: transparent;
    border: 1.5px solid #0d296a;
    color: #456bbc;
  }

  input[type="button"]:hover,
  button:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover {
    background-color: #0d296a;
    color: #fff;
  }

  input[type="button"]:active,
  button:active,
  input[type="submit"]:active,
  input[type="reset"]:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    background-color: transparent;
    border: none;
    color: #456bbc;
    padding: 10px 32px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    border: 1.5px solid #0d296a;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 25px;
    margin-bottom: 15px;
  }

  input[type="text"]:focus,
  input[type="email"]:focus {
    background-color: #fff;
    border-bottom: 0.5px solid #0d296a;
  }
  input[type="text"]:placeholder,
  input[type="email"]:placeholder {
    color: #cccccc;
  }
  /* ANIMATIONS */

  /* Simple CSS3 Fade-in-down Animation */
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  /* Simple CSS3 Fade-in Animation */
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fadeIn {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
  }

  .fadeIn.first {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  .fadeIn.second {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .fadeIn.third {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }

  .fadeIn.fourth {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
  }

  /* Simple CSS3 Fade-in Animation */
  .underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #0d296a;
    content: "";
    transition: width 0.2s;
  }

  .underlineHover:hover {
    color: #0d0d0d;
  }

  .underlineHover:hover:after {
    width: 100%;
  }

  /* OTHERS */

  *:focus {
    outline: none;
  }

  #icon {
    height: 200px;
    margin: 20px 0px;
  }
  .pass-icon {
    position: absolute;
    margin-left: -37px;
    margin-top: 15px;
  }
  .cover {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 100%;
  }
  #submit {
    padding: 10px 32px;
  }
}
.loader {
  border-radius: 0 !important;
  border: 0 !important;
  width: 30% !important;
  opacity: 0.7;
  height: 30% !important;
}
.LogInUI {
  html {
    font-family: normalGadugi;
    background-size: cover !important;
    background-repeat: no-repeat;
    height: 100%;
    margin: 0;
  }

  body {
    font-family: normalGadugi;
    background-size: cover !important;
    background-repeat: no-repeat;
    overflow: auto;
    height: 100%;
    margin: 0;
  }

  .available-heigh {
    height: -webkit-fill-available;
  }

  a {
    color: #0d296a;
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
  }

  h2 {
    text-align: center;
    font-size: 16px;
    font-family: normalGadugi;
    text-transform: capitalize;
    display: inline-block;
    margin: 40px 8px 10px 8px;
    color: #cccccc;
    
  }

  /* STRUCTURE */

  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
  }

  #formContent {
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    // width: 98%;
    // max-width: 406px;
    position: relative;
    // border-radius: 40px;
    padding: 0px;
    text-align: center;
    margin: auto;
    box-shadow: 0px 2px 5px 0px #bbc5ff;
    // margin-left: 30px;
    height: 350px;
    form{
      margin-bottom: 10px;
      padding-top: 60px;
      h2{
        margin: 0!important;
      }
      input{
        width: 100%;
        box-sizing: border-box;
      }
      .form-check{    
      margin-top: 15px;
      .form-check-input{
        width: 10%;
      }
      }
      input[type="submit"]{
        font-size: 15px !important;
        margin-bottom: 0!important;
        margin-top: 25px!important;
        border-radius: 4px!important;
        padding: 8px 6px!important;
      }
    }
  }

  #formFooter {
    padding-bottom: 25px;
    text-align: center;
  }

  /* TABS */

  h2 {
    color: #959595;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 0rem;
  }

  /* FORM TYPOGRAPHY*/

  input[type="button"],
  button,
  input[type="submit"],
  input[type="reset"],
  input[type="password"],
  select {
    background-color: #56baed;
    border: none;
    color: #495057;
    padding: 13px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  input[type="button"],
  button,
  input[type="submit"],
  input[type="reset"],
  input[type="password"],
  select {
    background: #0d296a;
    border-radius: 25px;
    border: 1.5px solid #0d296a;
    color: #fff;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 13px;
    margin-bottom: 15px;
  }

  input[type="button"]:hover,
  button:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover {
    background-color: #0d296a;
    color: #fff;
  }

  input[type="button"]:active,
  button:active,
  input[type="submit"]:active,
  input[type="reset"]:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select {
    background-color: transparent;
    border: none;
    color: #495057;
    padding: 8px 6px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    background: #fff;
    width: 100%;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-radius: 25px;
    margin-bottom: 8px;
    border: 1px solid #fff;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus,
  select:focus {
    background-color: #fff;
    box-shadow: 0px 0px 5px #ccc;
  }

  /* ANIMATIONS */

  /* Simple CSS3 Fade-in-down Animation */
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  /* Simple CSS3 Fade-in Animation */
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
  }

  .fadeIn.first {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  .fadeIn.second {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .fadeIn.third {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }

  .fadeIn.fourth {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
  }

  /* Simple CSS3 Fade-in Animation */
  .underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #0d296a;
    content: "";
    transition: width 0.2s;
  }

  .underlineHover:hover {
    color: #0d0d0d;
  }

  .underlineHover:hover:after {
    width: 100%;
  }

  /* OTHERS */

  *:focus {
    outline: none;
  }

  #icon {
    height: 85px;
    margin: auto;
  }
  /*.pass-tog {
  width: 86.5% !important;
  border-radius: 25px 0px 0px 25px !important;
  margin-right: 0 !important;
  border-right: 0 !important;
  float: left;
  margin: 5px 0px;
}*/
  /*.suffix {
  border: 1.5px solid #0d296a;
  padding: 6px 9px 8px 8px;
  border-radius: 0px 25px 25px 0px;
  border-left: 0;
  font-size: 20px;
  background: #0d296a;
  color: #fff;
  cursor: pointer;
  float: right;
  width: 11.5%;
  margin: 5px 0px;
}*/
  .suffix-icon {
    vertical-align: middle;
    width: 2rem;
  }

  .pass-cont {
    display: inline-table;
    width: 100%;
  }

  #pop_ForgotPassword {
    background-size: cover !important;
    background-repeat: no-repeat;
  }

  #formContent h2 {
    font-size: 2rem;
    // margin-top: 1rem;
    // margin-bottom: 1rem;
  }

  .absolute {
    position: absolute;
  }

  form {
    margin-bottom: 5rem;
  }

  #formContent form {
    margin-bottom: 0rem;
  }

  .Register-width,
  .mes {
    width: 100% !important;
    max-width: 100% !important;
  }

  body {
    overflow: auto;
  }

  .login_wrapper {
    margin: 0px auto;
    margin-top: 0%;
    max-width: 100%;
  }

  #formContent {
    padding: 0 30px;
  }

  .field-validation-error {
    color: white;
    position: absolute;
    height: 40px;
    top: -40px;
    background: #06114c;
    width: 100%;
    left: 0;
    font-size: 11px;
    text-align: center;
    border-radius: 9px;
    padding: 5px;
    display: none;
  }

  .field-validation-error::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .error-ico:hover + .field-validation-error {
    display: block;
  }

  .error-msg input {
    border-color: red;
  }

  .error-ico {
    display: none !important;
  }

  .error-msg .error-ico {
    display: block !important;
    position: absolute;
    right: 5px;
    bottom: 17px;
    color: #f24343ab;
    border: 1px solid;
    padding: 2px 4px;
    font-size: 14px;
    border-radius: 21px;
  }

  .popup #formContent {
    margin: 0px;
  }

  .u-centerX {
    margin: auto !important;
  }

  .fadeInDown {
    margin-bottom: 3rem;
  }

  .mes {
    margin: auto;
    padding-top: 7px;
  }

  .center-reset {
    margin: auto;
    margin-top: 22%;
    margin-bottom: 21%;
  }

  .mt-7 {
    margin-top: 7rem !important;
  }

  .confirm {
    padding: 25% 6%;
    color: #333;
  }

  a:hover,
  a:focus {
    color: #0d296a;
    text-decoration: underline;
  }

  .confirm-title {
    font-size: 2rem !important;
  }

  #confirm .js-close {
    display: none;
  }
 
  .right-form {
    margin: auto;
    // margin-right: 0px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .logo-comp {
    // background: #ffffff;
    // width: 70px;
    position: relative;
    top: 100px;
    // height: 88px;
    // border-radius: 83px;
    // margin: auto;
    z-index: 99;
    // box-shadow: 1px 1px 7px 3px #ccc;
    // padding: 18px 27px;
    text-align: center;
  }

  #formContent {
    // margin-top: -54px !important;
    padding-top: 50px;
    padding-bottom: 15px;
  }

  .fa.fa-eye,
   .fa.fa-eye-slash{
    display: inline-block;
    font-size: small;
    // position: absolute;
    // top: 190px;
    // right: 30px;
    color: #333;
  }
  .hidePassword{
    position: relative;
    .fa.fa-eye,
    .fa.fa-eye-slash{
      position: absolute;
      bottom: 3px;
      right: 12px;
    }
  }
  .remamber {
    margin: 15px 0px;
    input{
      border: 1px solid #eee;
      border-radius: 4px;
      margin: 5px 0 8px;
      &::placeholder{
        font-size: 12px;
        padding-left: 8px;
      }
    }
  }

  .right-form .full-hight {
    min-height: 99vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-form .middle-item {
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
  }

  #ForgotPasswordForm {
    position: absolute;
  }

  .second-img {
    left: -1px;
    position: absolute;
    width: 25%;
    bottom: 0px;
  }

  .m-r-non {
    margin: auto;
    margin-right: 0;
  }

  .right-15 {
    right: 15px !important;
  }

  .mr-1 {
    margin-right: 1rem;
  }

  .ml-1 {
    margin-left: 1rem;
  }
  .css-typing p {
    border-right: 0.15em solid #0d296a;
    font-size: 23px;
    font-weight: bolder;
    white-space: nowrap;
    text-shadow: 2px 2px #f7f7f7;
    overflow: hidden;
    color: #0d296a;
    line-height: 2;
    font-family: modernafont;
  }

  .css-typing p:nth-child(1) {
    width: 16.3em;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(2) {
    width: 11.5em;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing p:nth-child(3) {
    width: 10.3em;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 20px;
  }

  @keyframes type {
    0% {
      width: 0;
    }

    99.9% {
      border-right: 0.15em solid #0d296a;
    }

    100% {
      border: none;
    }
  }

  @-webkit-keyframes type {
    0% {
      width: 0;
    }

    99.9% {
      border-right: 0.15em solid #0d296a;
    }

    100% {
      border: none;
    }
  }

  @keyframes type2 {
    0% {
      width: 0;
    }

    1% {
      opacity: 1;
    }

    99.9% {
      border-right: 0.15em solid #0d296a;
    }

    100% {
      opacity: 1;
      border: none;
    }
  }

  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }

    1% {
      opacity: 1;
    }

    99.9% {
      border-right: 0.15em solid orange;
    }

    100% {
      opacity: 1;
      border: none;
    }
  }

  @keyframes type3 {
    0% {
      width: 0;
    }

    1% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes type3 {
    0% {
      width: 0;
    }

    1% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }

  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
  .css-typing {
    position: absolute;
    top: 45%;
    left: 34%;
    margin-top: -50px;
    margin-left: -30px;
    width: 100px;
    height: 100px;
  }

  @media only screen and (max-width: 1300px) {
    .css-typing {
      left: 7%;
    }
    .second-img {
      display: none;
    }
  }
  @media only screen and (max-width: 930px) {
    .css-typing p {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 852px) {
    .right-form {
      width: 100% !important;
      margin: auto !important;
    }

    .css-typing {
      left: 29%;
      top: 7%;
    }

    .middle-item {
      margin-top: 20vh !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .suffix {
      width: 11.5%;
    }
    .second-img {
      opacity: 0.4;
    }
    .css-typing {
      left: 29%;
      top: 7%;
    }
    .middle-item {
      margin-top: 20vh !important;
    }
  }
  @media only screen and (max-width: 320px) {
    .css-typing {
      display: none;
    }

    .middle-item {
      margin-top: auto !important;
    }
    #formContent {
      padding-right: 25px;
      padding-left: 17px;
    }
  }
}
